import React, {useState} from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import SEO from "../components/seo"

const Wordpress = () => {


  return(
  <Layout className='bg-slate-50'>
    <SEO title={'Brandon Jewell'} description={"I've spent the last decade designing, developing, and problem solving for startups, unicorns, and other businesses."}/>
    <div className='max-w-[550px] mx-auto px-4 py-16 article'>
    	<h1>🦸 The Technical Sidekick for Your Marketing Team</h1>
    	<p>Greetings, fellow digital adventurers! I’m Brandon Jewell, your soon-to-be indispensable sidekick in the thrilling world of web development and marketing tech. My decade-long journey has crafted me into a veritable superhero of the digital realm, ready to bring your marketing feats to new heights.</p>


    	<h1>🛡 Master of the Marketing Universe</h1>
    	<p>In the bustling tech metropolis of Boulder, CO, I donned my cape and joined forces with Uplight, a startup destined for unicorn glory. As their solo marketing developer, I was the silent guardian, the watchful protector of their entire digital domain.</p>


    	<h1>🌐 Crafting Digital Wonderlands</h1>
    	<p>From heroic marketing sites and high-converting landing pages to meticulously coded email templates, my arsenal of skills knows no bounds. I've forged knowledgebases to empower users, and content sites that weave narratives as captivating as any epic tale.</p>


    	<h1>🚀 Microsites and More: A Saga of Success</h1>
    	<p>Microsites sprang to life under my watch, each a unique beacon in the vast digital cosmos. With content management akin to wielding Mjolnir, I ensured every piece of gated content and asset download was but a click away, ready to engage and inspire.</p>


    	<h1>📈 Integrations Assemble!</h1>
    	<p>No superhero sidekick is complete without a quiver full of integrations. I’ve mastered the art of blending marketing, analytics, sales, and SEO integrations into a seamless symphony, propelling our digital endeavors to stellar success.</p>



    	<h1>🔍 SEO Mastery and Analytics Acumen</h1>
    	<p>With SEO integrations as my compass, I navigated the labyrinth of search engines, ensuring our digital presence soared to the top. Analytics became my crystal ball, offering insights and foresights to constantly elevate our marketing game.</p>


    	<h1>📅 Webinars, Events, and Beyond</h1>
    	<p>Webinars and events? Consider them handled. I crafted experiences that brought communities together, turning every online gathering into a digital extravaganza.</p>


    	<h1>💬 Your 24/7 Guardian on Slack</h1>
    	<p>And when the digital night is darkest? Fear not, for I am but a Slack message away. Always on call, communicative, and as reliable as the North Star, I am here to ensure your marketing mission is never in peril.</p>


    	<h1>🚀 Embark on the Next Adventure</h1>
    	<p>So, dear marketing mavens, are you ready to turn the page to our next epic chapter? With me as your technical sidekick, your marketing team is poised for unparalleled glory.</p>

    	<p>Let the adventure begin! connect@brandonjewell.com</p>



    </div>

  </Layout>
)}

export default Wordpress